import React from 'react'
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout'
import AnnouncementTable from '../../components/Announcements/AnnouncementTable'

const Announcements = () => {
    return (
        <DashboardLayout>
            <AnnouncementTable/>
        </DashboardLayout>
    )
}

export default Announcements
import React, { useState } from 'react';

const AnnouncementButton = ({ count, announcements }) => {
    const [isOpen, setIsOpen] = useState(false); // To track if the announcements dropdown is open
    const [activeAnnouncementIndex, setActiveAnnouncementIndex] = useState(null); // Track which announcement is clicked
    const [visitedAnnouncements, setVisitedAnnouncements] = useState([]); // Track visited announcements
    const [closing, setClosing] = useState(false); // Track the closing animation

    const handleAnnouncementClick = (index) => {
        setActiveAnnouncementIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle description visibility

        // Mark the announcement as visited
        if (!visitedAnnouncements.includes(index)) {
            setVisitedAnnouncements([...visitedAnnouncements, index]);
        }
    };

    const handleMouseLeave = () => {
        setClosing(true); // Start the closing animation
        setTimeout(() => {
            setIsOpen(false); // Close dropdown after animation
            setClosing(false); // Reset the closing state
        }, 400); // Match this duration with the CSS animation timing
    };

    return (
        <div className="relative flex items-center justify-center">
            {/* Announcement Button */}
            <button
                className="relative px-4 py-1.5 bg-purpleLight text-white hover:opacity-70 transition rounded"
                onClick={() => setIsOpen(!isOpen)} // Toggle dropdown on click
            >
                Announcements
                {count > 0 && (
                    <span className="absolute -top-2 -right-2 w-6 h-6 bg-red-500 text-white text-xs rounded-full flex items-center justify-center animate-pulse">
                        {count}
                    </span>
                )}
            </button>

            {/* Recent Announcements Dropdown */}
            {isOpen && (
                <div
                    className={`absolute top-full mt-2 w-96 bg-white shadow-lg rounded-lg z-50 transition-all duration-500 ease-out transform ${closing ? 'opacity-0 translate-y-[-20px]' : 'opacity-100 translate-y-0'
                        }`}
                    onMouseLeave={handleMouseLeave} // Close on mouse leave
                >
                    <h4 className="font-bold mb-2 flex w-full bg-purpleLight rounded-t-md p-3 text-white text-lg">
                        Recent Announcements
                    </h4>
                    {announcements.length > 0 ? (
                        <ol className="text-gray-700 px-1">
                            {announcements.map((announcement, index) => (
                                <li
                                    key={index}
                                    className={`mb-1 ${announcements?.length > 1 && index !== announcements?.length - 1
                                            ? 'border-b-2'
                                            : ''
                                        }`}
                                >
                                    <div
                                        className={`flex items-center w-full cursor-pointer p-2 hover:bg-purple-100 rounded group ${visitedAnnouncements.includes(index)
                                                ? 'font-normal' // Visited announcements are normal
                                                : 'font-bold' // Unvisited announcements are bold
                                            }`}
                                        onClick={() => handleAnnouncementClick(index)}
                                    >
                                        {/* Apply truncate class to restrict width and show ellipsis */}
                                        <span className='w-[70%] truncate'>{index + 1}. {announcement?.heading}</span>
                                        <span className='text-xs text-slate-400 hover:text-white'>
                                            {activeAnnouncementIndex === index ? "show less" : "show more"}
                                        </span>
                                    </div>

                                    {/* Show description only if the current announcement is clicked */}
                                    {activeAnnouncementIndex === index && (
                                        <div
                                            className="text-base mt-2 px-2"
                                            dangerouslySetInnerHTML={{ __html: announcement?.description }}
                                        ></div>
                                    )}
                                </li>
                            ))}
                        </ol>
                    ) : (
                        <p className="text-gray-500">No new announcements</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default AnnouncementButton;
